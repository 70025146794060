import * as React from "react";

import Section from "@components/section";
import Main from "./components/main";
import Nav from "./components/nav";
import generateContentSections from "./components/generateContentSections";

import * as styles from "./training-post.module.css";

const OfferPost = (props) => {
  const { id, postType, info, title, location, content } = props.data;

  const hasDate = info?.dateStart ? true : false;

  return (
    <article className={styles.training}>
      <Section
        className={styles.post}
        innerClassName={styles.post__inner}
        innerVariant={"alt"}
      >
        <Main
          data={{
            info: info,
            title: title,
            location: location,
          }}
          postType={postType}
          id={id}
        />
        {content && <Nav data={content} />}
      </Section>
      {generateContentSections(postType, content, id, hasDate)}
    </article>
  );
};

export default OfferPost;
