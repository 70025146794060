import * as React from "react";
import * as styles from "./banner-person.module.css";

import Section from "../../components/section";
import SectionHeader from "../../components/sectionHeader";

import CuttedImage from "../../components/cuttedImage";

const BannerPerson = (props) => {
  const { headlines, description, image } = props.data;
  const { id } = props;

  const bgColor = "light-to-default";
  // API

  const sectionDecorColor =
    (bgColor === "default-to-light" && "grayWhite") ||
    (bgColor === "dark-to-light" && "blackWhite") ||
    "whiteGray";

  const sectionTheme =
    (bgColor === "dark-to-light" && "light") ||
    (bgColor === "light-to-default" && "light") ||
    "";

  return (
    <Section
      className={styles.banner}
      decor={sectionDecorColor}
      theme={sectionTheme}
      innerVariant={"alt"}
      innerClassName={styles.banner__inner}
    >
      <div className={styles.content} id={id}>
        <div className={styles.main}>
          <SectionHeader
            headlines={headlines}
            subVariant={"sm"}
            className={styles.header}
            classNameSub={styles.header__sub}
          />
        </div>
        <div className={styles.illustration}>
          <CuttedImage
            image={image}
            cutValue={60}
            cutSide="left-top"
            className={styles.img__wrap}
          />
        </div>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Section>
  );
};

export default BannerPerson;
