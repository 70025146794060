import * as React from "react";

import LocationItem from "@components/locationItem";

const Location = (props) => {
  const { location } = props;

  return <LocationItem data={{ place: location.name }} />;
};

export default Location;
