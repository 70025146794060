import * as React from "react";

import Clock from "@images/svg/icon_clock.inline.svg";

import * as styles from "../../training-post-main.module.css";

const Schedule = (props) => {
  const { data } = props;

  return (
    <div className={styles.schedule}>
      <span className={styles.item__icon}>
        <Clock />
      </span>
      <p className={styles.item__label}>
        <strong>{data.dateStart}</strong>
        {data.hours}
      </p>
    </div>
  );
};

export default Schedule;
