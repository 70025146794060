import * as React from "react";
import * as styles from "./content-box.module.css";

import { stringToId } from "../../../../components/helpers";

import "./remote-style.css";

const ContentBox = (props) => {
  const { data, variant } = props;

  const boxClassName = `${styles.box} ${
    variant ? styles[`box__${variant}`] : ""
  }`;

  return (
    <div className={boxClassName} id={stringToId(data?.title)}>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: data?.content }}
      ></div>
    </div>
  );
};

export default ContentBox;
