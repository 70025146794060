import React from "react";
import { graphql } from "gatsby";

import { Helmet } from "react-helmet";

import Header from "../layouts/header";
import Footer from "../layouts/footer";
import Path from "../components/path";
import Cookies from "../components/cookies";
import { removeHardSpace } from "../components/helpers";

import { currentSections } from "../data/mapSections"; // eslint-disable-line

import OfferPost from "../layouts/offerPost";

import ListPostIntro from "../layouts/listPostsIntro";

import { mapListPostIntro } from "../data/sectionsMapMethods";

export default function TrainingPostTemplate({ data }) {
  const trainingPost = data?.allWpTraining?.nodes[0];

  const seo = trainingPost?.seo;

  const menuItems = data?.wpMenu?.menuItems.nodes;
  const footerProps = data?.wp?.footerSettings;
  const footerLinks = data.allWpMenu?.edges;

  const allTrainings = mapListPostIntro({
    title: "Zobacz także",
    typeList: "trainings",
    buttonMoreLabel: "Zobacz cały kalendarz szkoleń",
  });

  const privateBreadcrumbs = [
    seo?.breadcrumbs[0],
    { text: "Szkolenia i seminaria", url: "/szkolenia" },
    seo?.breadcrumbs[1],
    seo?.breadcrumbs[2],
  ];

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "pl",
        }}
      >
        {/* {/* <link rel="stylesheet" href="https://use.typekit.net/fyu0edh.css" /> */}
        <meta charset="UTF-8" />

        <title>{removeHardSpace(seo?.title)}</title>
        <meta name="description" content={seo?.metaDesc} />
        <meta name="keywords" content={seo?.metaKeywords} />

        <meta property="og:title" content={seo?.opengraphTitle} />
        <meta property="og:type" content={seo?.opengraphType} />
        <meta property="og:url" content={seo?.opengraphUrl} />
        <meta property="og:image" content={seo?.opengraphImage?.sourceUrl} />
        <meta property="og:site_name" content={seo?.opengraphSiteName} />

        <meta name="twitter:title" content={seo?.twitterTitle} />
        <meta name="twitter:description" content={seo?.twitterDescription} />
        <meta name="twitter:image" content={seo?.twitterImage} />
      </Helmet>

      <Header links={menuItems} variant={"light"} />
      <main>
        <Path list={privateBreadcrumbs} variant={"light"} />
        <OfferPost
          data={{
            id: trainingPost?.databaseId,
            postType: "training",
            info: trainingPost?.groupTrainingPost,
            title: trainingPost?.title,
            location: trainingPost?.trainingCategories?.nodes[0],
            content: trainingPost?.groupTrainingPost?.groupContent,
          }}
        />
        <ListPostIntro data={allTrainings} />
      </main>
      <Footer data={{ footerProps, footerLinks }} />
      <Cookies />
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    allWpTraining(filter: { id: { in: [$id] } }) {
      totalCount
      nodes {
        id
        databaseId
        title
        content
        trainingCategories {
          nodes {
            id
            slug
            name
          }
        }
        seo {
          title
          twitterTitle
          metaDesc
          metaKeywords
          opengraphDescription
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          opengraphImage {
            altText
            sourceUrl
          }
          twitterImage {
            sourceUrl
          }
          twitterDescription
          breadcrumbs {
            text
            url
          }
        }
        slug
        status
        groupTrainingPost {
          author
          dateStart
          fieldGroupName
          hours
          price
          groupContent {
            ... on WpTraining_Grouptrainingpost_GroupContent_GroupContentSection {
              fieldGroupName
              title
              content {
                ... on WpTraining_Grouptrainingpost_GroupContent_GroupContentSection_Content_Text {
                  description
                  fieldGroupName
                }
                ... on WpTraining_Grouptrainingpost_GroupContent_GroupContentSection_Content_Prices {
                  fieldGroupName
                  pricesList {
                    description
                    price
                    priceText
                    title
                    vat
                  }
                }
                ... on WpTraining_Grouptrainingpost_GroupContent_GroupContentSection_Content_Author {
                  description
                  fieldGroupName
                  title
                  image {
                    altText
                    sizes
                    srcSet
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    wp {
      generalSettings {
        url
        title
        description
        language
      }
      footerSettings {
        settings {
          description
          address
          address2
          copyright
          createdBy
          socials {
            fieldGroupName
            icon
            url
          }
        }
      }
    }
    wpMenu(name: { in: "Menu top" }) {
      name
      menuItems {
        nodes {
          id
          label
          order
          url
          path
          target
          parent {
            id
          }
          parentId
          connectedNode {
            node {
              ... on WpPage {
                id
                groupPage {
                  menuIcon {
                    altText
                    sourceUrl
                  }
                }
                pageOptions {
                  label
                  disableView
                  disableButtonFly
                }
              }
            }
          }
        }
      }
      parent {
        id
        ... on WpPage {
          id
        }
      }
      nodeType
    }

    allWpMenu {
      edges {
        node {
          id
          count
          name
          slug
          menuItems {
            nodes {
              order
              title
              uri
              path
              parentId
              id
              label
              connectedNode {
                node {
                  ... on WpPage {
                    id
                    pageOptions {
                      label
                      disableView
                      disableButtonFly
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
