import * as React from "react";

import { useEffect } from "react";
import { stringToId } from "@components/helpers";

import * as styles from "./training-post-nav.module.css";

const Nav = (props) => {
  const { data } = props;

  useEffect(() => {
    document
      .querySelectorAll(`.${styles.nav} a[href^="#"]`)
      .forEach((anchor) => {
        anchor.addEventListener("click", function (e) {
          e.preventDefault();

          const bodyRect = document.body.getBoundingClientRect();
          const elRect = document
            .querySelector(this.getAttribute("href"))
            ?.getBoundingClientRect();

          if (elRect)
            window.scrollTo({
              top: elRect.top - bodyRect.top - 120,
              behavior: "smooth",
            });
        });
      });
  }, []);

  return (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        {data.map((item, i) => {
          return (
            <li
              className={`${styles.list__item} ${
                i === 0 ? styles.item__active : ""
              }`}
              key={`nav-item-${i}`}
            >
              <a
                href={`#${stringToId(item.title)}`}
                className={styles.item__label}
                dangerouslySetInnerHTML={{ __html: item.title }}
                aria-label={stringToId(item.title)}
              />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
