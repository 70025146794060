// extracted by mini-css-extract-plugin
export var bold = "Oh";
export var info__item = "Hh";
export var info__list = "Fh";
export var info__work = "Gh";
export var item__icon = "Mh";
export var item__label = "Nh";
export var label = "Dh";
export var main = "Bh";
export var main__info = "Eh";
export var main__primary = "Ch";
export var price = "Jh";
export var salary = "Kh";
export var schedule = "Ih";
export var type = "Lh";