import * as React from "react";

import Boxes from "@images/svg/Boxes.inline.svg";

import * as styles from "../../training-post-main.module.css";

const Price = (props) => {
  const { price } = props;

  return (
    <div className={styles.price}>
      <span className={styles.item__icon}>
        <Boxes />
      </span>
      <p className={`${styles.item__label} ${styles.bold}`}>{price}</p>
    </div>
  );
};

export default Price;
