import * as React from "react";
import * as styles from "./training-post-main.module.css";

import SectionHeader from "@components/sectionHeader";

import Label from "@components/label";
import ButtonLink from "@components/button/buttonLink";

import Location from "./components/location";
import Schedule from "./components/schedule";
import Price from "./components/price";
import Author from "./components/author";
import WorkType from "./components/workType";
import Kind from "./components/kind";

const InfoItems = (props) => {
  const { location, info, postType, id } = props;

  const list =
    postType === "job"
      ? ["location", "kind", "workType", "button"]
      : ["location", "schedule", "price", "author"];

  const mapComponents = {
    location: location && <Location location={location} />,
    price: info.price && <Price price={info.price} />,
    author: info.author && <Author author={info.author} />,
    workType: <WorkType type={info.hours} />,
    kind: <Kind type={info.typeOfJob} />,
    schedule:
      (info.dateStart && (
        <Schedule data={{ dateStart: info.dateStart, hours: info.hours }} />
      )) ||
      (info.hours && <Schedule data={{ dateStart: info.hours }} />),
    button: (
      <ButtonLink
        to="/praca/zapis-do-pracy"
        state={{ activePost: id }}
        theme="theme10"
        isDefault
        isArrow
        variant={"third"}
      >
        Aplikuj
      </ButtonLink>
    ),
  };

  return list.map((item, i) => {
    return (
      <li className={styles.info__item} key={`info-${i + 1}`}>
        {mapComponents[item]}
      </li>
    );
  });
};

const Main = (props) => {
  const { title, info, location } = props.data;
  const { id, postType } = props;

  const mainClassName = `${styles.main} ${
    postType === "job" ? styles.main__primary : ""
  }`;

  const infoClassName = `${styles.main__info} `;

  const label =
    postType === "job" ? null : (
      <Label className={styles.label}>szkolenie</Label>
    );

  return (
    <div className={mainClassName}>
      {label}
      <SectionHeader headlines={{ main: title }} isMainHeader />
      <div className={infoClassName}>
        <ul className={styles.info__list}>
          <InfoItems
            location={location}
            info={info}
            postType={postType}
            id={id}
          />
        </ul>
      </div>
    </div>
  );
};

export default Main;
