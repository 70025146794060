import * as React from "react";

import Section from "../../../components/section";
import ContentBox from "./contentBox";
import ButtonLink from "../../../components/button/buttonLink";
import BannerPerson from "../../banner-person";
import { stringToId } from "../../../components/helpers";
import * as styles from "../training-post.module.css";

const sections = {
  training: {
    text: "Training_Grouptrainingpost_GroupContent_GroupContentSection_Content_Text",
    author:
      "Training_Grouptrainingpost_GroupContent_GroupContentSection_Content_Author",
  },
  job: {
    text: "Job_Groupjobspost_GroupContent_GroupContentSection_Content_Text",
    author: "Job_Groupjobspost_GroupContent_GroupContentSection_Content_Author",
  },
};

const formLink = {
  training: "/szkolenia/zapis-na-szkolenie",
  job: "/praca/zapis-do-pracy",
};

const generateContentSections = (postType, data, postId, hasDate) => {
  const sectionText = sections[postType]?.text;
  const sectionAuthor = sections[postType]?.author;
  const postClassName = `${styles.post} ${
    postType === "job" ? styles.post__primary : ""
  }`;

  return data?.map((section, index) => {
    const title = section.title;
    const content = section.content[0];
    const additionalContent = section.content[1];
    var isSeparator = false;
    var isButton = false;

    if (content.fieldGroupName === sectionText) {
      if (
        index + 1 < data.length &&
        data[index + 1].content[0].fieldGroupName === sectionText
      ) {
        if (postType !== "job") {
          isSeparator = true;
        }
      }

      const samePosts = data.filter((el) => {
        return el.content[0].fieldGroupName === content.fieldGroupName;
      });

      if (samePosts[samePosts.length - 1].content[0] === content)
        isButton = true;

      return (
        <Section
          className={postClassName}
          innerClassName={styles.post__inner}
          innerVariant={"alt"}
          key={title}
        >
          <ContentBox
            data={{ title: title, content: content.description }}
            variant={postType === "job" ? "primary" : ""}
          />
          {isSeparator && <span className={styles.separator} />}
          {isButton && (
            <ButtonLink
              isDefault
              theme="theme5"
              className={styles.button}
              to={formLink[postType]}
              state={{ activePost: postId }}
              disabled={postType !== "job" && !hasDate}
            >
              {postType === "job" ? "Aplikuj" : "Zapisz się"}
            </ButtonLink>
          )}
          {isButton && postType !== "job" && !hasDate && (
            <div className={styles.post__additional}>
              Zapisy na szkolenie będą wkrótce otwarte
            </div>
          )}
          {additionalContent?.description && (
            <div
              className={styles.post__additional}
              dangerouslySetInnerHTML={{
                __html: additionalContent?.description,
              }}
            />
          )}
        </Section>
      );
    }
    if (content.fieldGroupName === sectionAuthor) {
      return (
        <BannerPerson
          data={{
            headlines: { main: content.title, comp: "Prowadzący" },
            description: content.description,
            image: content.image,
          }}
          id={stringToId(title)}
          key={title}
        />
      );
    }

    return "";
  });
};
export default generateContentSections;
