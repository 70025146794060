import * as React from "react";

import * as styles from "../../training-post-main.module.css";

const Author = (props) => {
  const { author } = props;

  return <p className={styles.item__label}>{author}</p>;
};

export default Author;
