import * as React from "react";

import Clock from "@images/svg/icon_clock.inline.svg";

import * as styles from "../../training-post-main.module.css";

const WorkType = (props) => {
  const { type } = props;

  return (
    <div className={styles.type}>
      <span className={styles.item__icon}>
        <Clock />
      </span>
      <p className={styles.item__label}>{type}</p>
    </div>
  );
};

export default WorkType;
